<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('student_documents')"
                        :isFilter="false"
                />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('student_documents')"
                              :isFilter="false"
                />
            </template>

            <ValidationObserver ref="form">
                <b-row>
                    <b-col cols="12" md="8">
                        <b-row>
                            <b-col md="6">
                                <ValidationProvider name="faculty_code" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('faculty')">
                                        <faculty-selectbox v-model="formData.faculty_code"
                                                           :validate-error="errors[0]"
                                        />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                                <ValidationProvider name="program_code" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('program')">
                                        <program-selectbox v-model="formData.program_code"
                                                           :faculty_code="formData.faculty_code"
                                                           :is_faculty_code_required="true"
                                                           :validate-error="errors[0]"
                                        />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                                <ValidationProvider name="student_status" :rules="formData.student_numbers ? '':'required'" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('student_status')">
                                        <parameter-selectbox v-model="formData.student_status"
                                                             code="student_statuses"
                                                             :validateError="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                                <ValidationProvider name="active_semester_status" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('semester_status')">
                                        <parameter-selectbox v-model="formData.active_semester_status"
                                                             code="semester_statuses"
                                                             :validateError="errors[0]"
                                        />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                                <ValidationProvider name="registration_academic_year" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('registration_academic_year')">
                                        <academic-years-selectbox v-model="formData.registration_academic_year"
                                                                  :validate-error="errors[0]"
                                        />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                                <ValidationProvider name="registration_type" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('registration_type')">
                                        <parameter-selectbox v-model="formData.registration_type"
                                                             code="registration_types"
                                                             :validate-error="errors[0]"
                                        />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                                <ValidationProvider name="registration_semester" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('registration_semester')">
                                        <parameter-selectbox v-model="formData.registration_semester"
                                                             code="semester"
                                                             :validate-error="errors[0]"
                                        />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                                <ValidationProvider name="document_type" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('document_type')">
                                        <document-type-selectbox v-model="formData.document_type"
                                                                 :validate-error="errors[0]"
                                        />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col md="4">
                        <ValidationProvider name="student_numbers" rules="" v-slot="{valid, errors}">
                            <b-form-group :label="$t('students')">
                                <lined-textarea v-model="formData.student_numbers"
                                                :nowrap="false"
                                                :disabled="false"
                                                :styles="{ height: '310px', resize: 'both' }"
                                                :validateError="errors[0]">
                                </lined-textarea>
                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>
                <div class="d-flex mt-2">
                    <b-button variant="primary" @click="create">{{ $t('create_document') }}</b-button>
                </div>
            </ValidationObserver>
        </app-layout>
    </div>
</template>

<script>
    // Template
    import AppLayout from '@/layouts/AppLayout'
    import Header from '@/layouts/AppLayout/Header'
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

    // Components
    import AcademicYearsSelectbox from '@/components/interactive-fields/AcademicYearsSelectbox';
    import DocumentTypeSelectbox from '@/components/interactive-fields/DocumentTypeSelectbox';
    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox';
    import LinedTextarea from '@/components/elements/LinedTextarea';
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
    import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox';

    // Services
    import StudentDocumentService from '@/services/StudentDocumentService';

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate';

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,

            AcademicYearsSelectbox,
            DocumentTypeSelectbox,
            FacultySelectbox,
            LinedTextarea,
            ParameterSelectbox,
            ProgramSelectbox,

            ValidationProvider,
            ValidationObserver
        },
        metaInfo() {
            return {
                title: this.$t('student_document_create')
            }
        },
        data() {
            return {
                formData: {
                    student_numbers: '',
                    faculty_code: null,
                    program_code: null,
                    student_status: null,
                    active_semester_status: null,
                    registration_type: null,
                    registration_academic_year: null,
                    registration_semester: null,
                    document_type: null
                }
            }
        },
        methods: {
            async create() {
                const isValid = await this.$refs.form.validate();
                if (isValid) {
                    const formData = {...this.formData}
                    if (this.formData.student_numbers === '') {
                        delete formData.student_numbers;
                    }
                    else {
                        formData.student_numbers = this.formData.student_numbers.split(/\n/)
                    }

                    StudentDocumentService.create(formData)
                                          .then(response => {
                                              this.$toast.success(this.$t('api.' + response.data.message));
                                              this.$router.push('/job/logs/detail/' + response.data.data.log_id);
                                          })
                                          .catch(e => {
                                              this.showErrors(e)
                                          })
                }
            }
        }
    }
</script>

